import Vue from "vue"
import Router from "vue-router"
import NProgress from "nprogress"
import "nprogress/nprogress.css"

import homePageLayout from "../layouts/homePageLayout";
import adminPageLayout from "../layouts/adminPageLayout";
import adminRoutes from "./adminRoutes";
import homeRoutes from './homeRoutes'
import clientRoutes from './clientRoutes'
import store from '../store/index'
import guest from './middleware/guest'
import auth from './middleware/auth'
import clientAdmin from "./middleware/clientAdmin";
import client from "./middleware/client";
import management from "./middleware/management";
import middlewarePipeline from './middlewarePipeline'
import clientPageLayout from "../layouts/clientPageLayout";
import clientAdminPageLayout from "../layouts/clientAdminPageLayout";
import clientAdminRoutes from "./clientAdminRoutes";
import stationManagerLayout from "../layouts/stationManagerLayout";
import stationManagerRoutes from "./stationManagerRoutes";
import supervisorPageLayout from "../layouts/supervisorPageLayout";
import supervisorRoutes from "./supervisorRoutes";
import shopKeeperLayout from "@/layouts/shopKeeperLayout.vue";
import shopKeeperRoutes from "@/routes/shopKeeperRoutes";
Vue.use(Router);

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        {
            component:homePageLayout,
            path:"/",
            children:[
                ...homeRoutes,
            ],
            meta: {
                middleware: [
                    guest
                ]
            },
        },
        {
            component:adminPageLayout,
            path:"/admin",
            children:[
                ...adminRoutes,
            ],
            meta: {
                middleware: [
                    auth,management
                ]
            },
        },
        {
            component:clientPageLayout,
            path:"/client",
            children:[
                ...clientRoutes,
            ],
            meta: {
                middleware: [
                    auth,client
                ]
            },
        },
        {
            component:clientAdminPageLayout,
            path:"/client-admin",
            children:[
                ...clientAdminRoutes,
            ],
            meta: {
                middleware: [
                    auth,clientAdmin
                ]
            },
        },
        {
            component:stationManagerLayout,
            path:"/station-manager",
            children:[
                ...stationManagerRoutes,
            ],
            meta: {
                middleware: [
                    auth
                ]
            },
        },
        {
            component:supervisorPageLayout,
            path:"/supervisor",
            children:[
                ...supervisorRoutes,
            ],
            meta: {
                middleware: [
                    auth
                ]
            },
        },
        {
            component:shopKeeperLayout,
            path:"/shop-keeper",
            children:[
                ...shopKeeperRoutes,
            ],
            meta: {
                middleware: [
                    auth
                ]
            },
        },
    ],
    scrollBehavior (to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash
            }
        }
    }
});
// router guards
router.beforeEach((to, from, next) => {
    NProgress.start();
    const nearestWithMiddleware = to.matched.slice().reverse().find(r => r.meta && r.meta.middleware);
    if (!nearestWithMiddleware) {
        return next()
    }
    const middleware = nearestWithMiddleware.meta.middleware;

    const context = {
        to,
        from,
        next,
        store
    };
    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    });
});

router.afterEach((to, from) => {
    NProgress.done();
});
export default router;
