<template>
  <span>
    <v-navigation-drawer app
                         v-model="drawer"
                         light
                         disable-resize-watcher
                         class="grey lighten-2">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            CFL Lamu
          </v-list-item-title>
          <v-list-item-subtitle>
            The smart fuel partner
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense
              nav>
        <v-list-item v-for="item in items"
                     :key="item.title"
                     :to="item.to"
                     link>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar color="grey lighten-3"
               light
               flat
               height="70px">
      <v-app-bar-nav-icon
          class="hidden-md-and-up"
          @click="drawer = !drawer"/>
      <!--            <v-spacer class="hidden-md-and-up"></v-spacer>-->
      <v-img class="mx-2 hidden-md-and-down"
             src="/img/logo.png"

             max-height="40"
             max-width="40"
             contain/>
      <v-toolbar-title class="font-weight-black headline text-center ml-2 align-center hidden-sm-and-down">Central Fuels &
        Lubricants
      </v-toolbar-title>
      <v-toolbar-title class="font-weight-black headline text-center ml-2 align-center hidden-sm-and-up">CFL LAMU
      </v-toolbar-title>
      <v-spacer class="hidden-sm-and-down"/>
      <div class="hidden-sm-and-down">
        <v-btn text exact to="/" data-cy="signinBtn">Home</v-btn>
        <!--        <v-btn text exact to="/about" data-cy="signinBtn">About</v-btn>-->
      </div>
      <div v-if="!isAuthenticated" class="hidden-sm-and-down">
        <v-btn text exact to="/login" data-cy="signinBtn">My Account</v-btn>
      </div>
      <div v-else>
        <v-btn outline color="white" @click="logout" data-cy="logout">Logout
        </v-btn>
      </div>
    </v-app-bar>
  </span>
</template>

<script>
export default {
  name: 'AppNavigation',
  data() {
    return {
      appTitle: 'CFL LAMU',
      drawer: false,
      items: [
        {title: 'Home', icon: 'mdi-home', to: "/"},
        // {title: 'Products', icon: 'mdi-view-dashboard',to:"/"},
        // {title: 'Services', icon: 'mdi-image',to:"/"},
        // {title: 'About', icon: 'mdi-help-box',to:"/"},
        // {title: 'Contact', icon: 'mdi-phone',to:"/"},
        {title: 'My Account', icon: 'mdi-login', to: "/login"},
      ],
    };
  },
  computed: {
    isAuthenticated() {
      return false
      // return this.$store.getters.isAuthenticated;
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('userSignOut');
    }
  }
};
</script>

<style scoped>
a {
  color: white;
  text-decoration: none;
}
</style>
