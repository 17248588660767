const ShopKeeperDashboard = ()=>import(/* webpackChunkName: "js/shopkeepers" */'@/pages/shopKeeper/Dashboard');
const CashSale = ()=>import(/* webpackChunkName: "js/shopkeepers" */'@/pages/shopKeeper/CreateCashSale.vue');
export default [
    {
        name:"ShopKeeperDashboard",
        path:"/shop-keeper",
        component:ShopKeeperDashboard
    },
    {
        name:"ShopSingleCashSale",
        path:"/shop-keeper/cash-sale/:id",
        component:CashSale,
        props:true
    },
]
