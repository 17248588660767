// import Vue from 'vue'
// import App from './App.vue'
import './registerServiceWorker'
// import router from './router'
// import store from './store'
//
// Vue.config.productionTip = false
//
// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')
import 'leaflet/dist/leaflet.css';

// import OverlayLoader from "@/components/widgets/helpers/OverlayLoader";

// require('./bootstrap');
require('./echo.js')
import Vue from 'vue';
import Chartkick from 'vue-chartkick'
import Chart from 'chart.js'
import VueHtmlToPaper from 'vue-html-to-paper';

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'http://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'http://unpkg.com/kidlat-css/css/kidlat.css',
    './test.css'
  ]
}

Vue.use(VueHtmlToPaper, options);
import VueMeta from 'vue-meta'

Vue.use(VueMeta)
// or, using the defaults with no stylesheet
Vue.use(VueHtmlToPaper);
Vue.use(Chartkick)
window.Vue = Vue;
import vuetify from './plugins/vuetify';
import router from './routes/index';
// import store from './store/index';
import App from "./App";
import {Form, HasError, AlertError} from 'vform';
import store from './store/index'
window.Form = Form;

import swal from 'sweetalert2';

window.swal = swal;
const Toast = swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  onOpen: (toast) => {
    toast.addEventListener('mouseenter', swal.stopTimer);
    toast.addEventListener('mouseleave', swal.resumeTimer)
  }
});
window.toast = Toast;
import excel from 'vue-excel-export'
Vue.use(excel);
// import vSelect from 'vue-select'
// Vue.component('vue-select', vSelect);
// import "vue-select/dist/vue-select.css";
import moment from "moment";
// import Print from 'vue-print-nb'

// Vue.use(Print);
Vue.filter('filterDate', function (myDate) {
  return moment(myDate).format('Do MMMM YYYY, h:mm:ss a');
});

Vue.filter('filterDateOnly', function (myDate) {
  return moment(myDate).format('dddd, MMMM D, YYYY');
});
Vue.filter('filterTime', function (myDate) {
  return moment(myDate).format('hh:mm:ss a');
});


Vue.filter('filterHumanDate', function (myDate) {
  return moment(myDate).format('Do MMMM YYYY, h:mm:ss a');
});
Vue.filter('filterStDate', function (myDate) {
  return moment(myDate).format('DD.MM.YY,hh:mm');
});
//UPPERCASE TEXT
Vue.filter('uppercaseText', function (text) {
  return text.charAt(0).toUpperCase() + text.slice(1);
});

//-===================
Vue.filter('allUppercase', function (text) {
  return text.toUpperCase()
});
// import df from './mixins/Permissions.vue';
// Vue.mixin(df);

// Vue.component('LoadingComponent',OverlayLoader);
const app = new Vue({
  vuetify,
  router,
  store,
  components:{App},
  render: h => h(App)
}).$mount('#app');
