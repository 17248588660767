<template>
    <VApp>
        <Navigation app class="mb-0"/>
        <VMain>
            <router-view/>
        </VMain>
        <VFooter
                app
                color="grey lighten-2"
                absolute
                fixed
                inset>
            <VContainer fluid>
                <VRow class="grey lighten-2 text-center ma-0"
                      justify="center"
                      cols="12">
                    <span class="caption">CFL Lamu Ltd &copy; {{ new Date().getFullYear() }} www.cfl-lamu.com</span>
                    <VSpacer/>
                    <span class="caption mr-1">Built by MoffHub Solutions  </span>
                </VRow>
            </VContainer>
        </VFooter>
    </VApp>
</template>

<script>
import Navigation from "../components/admin_components/AdminNavigation";
import HeaderItem from "../components/admin_components/HeaderItem";

export default {
    name: "adminPageLayout",
    metaInfo: {
        title: 'Dashboard',
        titleTemplate: '%s | CFL Admin'
    },
    components: {
        HeaderItem,
        Navigation
    },
    data() {
        return {
            links: [
                'Dashboard',
                'Settings'
            ],
        }
    }
}
</script>

<style scoped>
/*@import url('https://fonts.googleapis.com/css?family=Exo+2&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=PT+Sans&display=swap');

* {
    font-size: 9px;
}
</style>
