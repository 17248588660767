<template>
    <v-app app>
        <shop-keeper-navigation app class="mb-0"/>
        <v-main>
            <router-view/>
        </v-main>
        <v-footer app
            color="secondary"
            inset
            absolute
            padless>
            <v-container>
                <v-row class="secondary text-center white--text ma-1"
                       justify="center"
                       cols="12">
                    <span class="caption">CFL Lamu Ltd &copy; {{ new Date().getFullYear() }}</span>
                    <v-spacer/>
                    <span class="caption mr-1"> www.cfl-lamu.com </span>
                </v-row>
            </v-container>
        </v-footer>
    </v-app>
</template>

<script>
    import ShopKeeperNavigation from "../components/admin_components/ShopKeeperNavigation.vue";
    export default {
        name: "shopKeeperLayout",
        components:{ShopKeeperNavigation}
    }
</script>

<style scoped>

</style>
