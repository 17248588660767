<template>
<!--  <div class="scroll-y">-->
    <router-view/>
<!--  </div>-->
</template>


<script>
export default {
  name: "App",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'CFL LAMU',
    // all titles will be injected into this template
    titleTemplate: '%s | CFL A Petroleum Company'
  },
  data() {
    return {
      fab: false
    }
  },
  methods: {
    onScroll(e) {
      if (typeof window === 'undefined') return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 40
    },
    toTop() {
      this.$vuetify.goTo(0)
    }
  },
  created() {
  }
}
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Lato|Raleway&display=swap");
//@import url('https://fonts.googleapis.com/css? family=Oxygen:300,400,700&display=swap');
//@import url('https://fonts.googleapis.com/css? family=Comfortaa&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900');
@import url('https://fonts.googleapis.com/css?family=Material+Icons');

* {
  font-size: 12px;
  letter-spacing: 1.2px;
  font-family: "Lato", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}
</style>
