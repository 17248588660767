import Vue from 'vue'
import Vuetify from 'vuetify'
// import "../theme/default.styl"
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'
Vue.use(Vuetify);

const opts = {
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
    theme: {
        themes: {
            light: {
                primary: "#32a836",
                secondary: "#2e6ca8",
                accent: "#f88b00",
                error: "#ff0b48",
                info: "#64e5f3",
                success: "#4CAF50",
                warning: "#FFC107"
            },
            dark: {
                primary: '#432e96',
                secondary: '#b0bec5',
                anchor: '#8c9eff',
                accent: "#82B1FF",
                error: "#FF5252",
                info: "#2196F3",
                success: "#4CAF50",
                warning: "#FFC107"
            }
        }

    },
    customProperties: true
};

export default new Vuetify(opts)
