<template>
    <v-container>
        <v-navigation-drawer app
            v-model="drawer"
            dark
            disable-resize-watcher
            width="250"
            class="secondary">
<!--            <v-img src="/images/g3.png" height="100%">-->
                <template v-slot:prepend>
                    <v-list-item two-line>
                        <v-avatar color="" size="50">
                            <!--                        <v-icon>mdi-account</v-icon>-->
                            <v-img class="mx-2" src="/images/logo.png" max-height="40" max-width="40" contain/>
                            <!--                        <img src="https://randomuser.me/api/portraits/women/81.jpg">-->
                        </v-avatar>

                        <v-list-item-content class="ml-1">
                            <v-list-item-title>{{ user.firstname }}</v-list-item-title>
                            <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                            <v-list-item-subtitle>CFL Lamu</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </template>

                <v-divider></v-divider>

                <v-list dense nav>
                    <v-list-item v-for="item in items"
                        :key="item.title"
                        :to="item.to"
                        link
                        exact>
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item link
                        @click="logout">
                        <v-list-item-icon>
                            <v-icon color="error">mdi-logout</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Logout</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
<!--            </v-img>-->
        </v-navigation-drawer>
        <v-app-bar color="primary"
            light
            fixed
            app>
            <v-app-bar-nav-icon class=""
                @click="drawer = !drawer"/>
            <v-spacer class="hidden-sm-and-up"></v-spacer>
            <v-img class="mx-2"
                src="/images/logo.png"
                max-height="40"
                max-width="40"
                contain/>

            <v-toolbar-title class="headline text-center ml-2 align-center">Central Fuels &
                Lubricants
            </v-toolbar-title>
            <v-spacer class="hidden-sm-and-down"/>
<!--            <div class="hidden-sm-and-down">-->
<!--                <v-btn dark text exact to="/client" data-cy="signinBtn">Dashboard</v-btn>-->
<!--                &lt;!&ndash;                <v-btn text exact to="/admin/settings" data-cy="signinBtn">Settings</v-btn>&ndash;&gt;-->
<!--            </div>-->
<!--            <div>-->
<!--                <v-btn text color="white" dark @click="logout" data-cy="logout">-->
<!--                    <v-icon color="white">mdi-logout</v-icon>-->
<!--                    Logout-->
<!--                </v-btn>-->
<!--            </div>-->

<!--            <v-btn icon class="hidden-sm-and-down">-->
<!--                <v-icon>mdi-magnify</v-icon>-->
<!--            </v-btn>-->
        </v-app-bar>
    </v-container>
</template>

<script>
    export default {
        name: 'ClientAdminNavigation',
        data() {
            return {
                appTitle: 'CFL LAMU',
                drawer: true,
                user:[],
                items: [
                    {title: 'Dashboard', icon: 'mdi-view-dashboard', to: "/shop-keeper"},
                ],
            };
        },
        computed: {
            isAuthenticated() {
                // return false
                return this.$store.getters.auth;
            }
        },
        methods: {
            logout() {
                swal.fire({
                    title: 'Log out?',
                    text: "You will be logged out and Redirected",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Log me out!'
                }).then((result) => {
                    if (result.value) {
                        localStorage.removeItem('token');
                        localStorage.removeItem('CFLLamu');
                        this.$store.dispatch('logout_user');
                        toast.fire(
                            'Logged out!',
                            'Redirecting ....',
                            'success'
                        );
                        this.$router.push('/login');
                    }
                })
            }
        },
        created() {
            this.user = this.$store.state.user
        },
    };
</script>

<style scoped>
    a {
        color: white;
        text-decoration: none;
    }
</style>
