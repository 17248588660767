const ClientDashboard =()=>import(/* webpackChunkName: "js/client" */'../pages/clients/ClDashboard');
const ClAdmins =()=>import(/* webpackChunkName: "js/client" */'../pages/clients/ClAdmins');
const ClAccountPage =()=>import(/* webpackChunkName: "js/client" */'../pages/clientAdmin/ClAdminAccountPage');
const ClFuelOrders =()=>import(/* webpackChunkName: "js/client" */'../pages/clients/ClFuelOrders');
const ClSingeAdmin =()=>import(/* webpackChunkName: "js/client" */'../pages/clients/ClSingeAdmin');
const ClFuelPurchases =()=>import(/* webpackChunkName: "js/client" */'../pages/clients/ClFuelPurchases');
const ClAdminReports =()=>import(/* webpackChunkName: "js/client" */'../pages/clients/reports/ClAdminReports');
const ClFuelReports =()=>import(/* webpackChunkName: "js/client" */'../pages/clients/reports/ClFuelReports');
export default [
    {
        name:'ClientDashboard',
        path:"/client",
        component:ClientDashboard
    },
    {
        name:'ClAdmins',
        path:"/client/client-admins",
        component:ClAdmins
    },
    {
        name:'ClFuelOrders',
        path:"/client/fuel-orders",
        component:ClFuelOrders
    },
    {
        name:'ClSingeAdmin',
        path:"/client/client-admin",
        component:ClSingeAdmin
    },
    {
        name:'ClFuelPurchases',
        path:"/client/fuel-purchases",
        component:ClFuelPurchases
    },
    {
        name:'ClAccountPage',
        path:"/client/account/:id",
        component:ClAccountPage,
        props:true
    },
    {
        name:'ClAdminReports',
        path:"/client/reports/admin-reports",
        component:ClAdminReports
    },
    {
        name:'ClFuelReports',
        path:"/client/reports/fuel-reports",
        component:ClFuelReports
    }
]
