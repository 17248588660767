export default function guest ({ next, store }){
    if(store.getters.auth){
        let user = store.getters.getUser;
        if (user.userType === 'management') {
            return next({
                name:'AdminDashboard'
            })
        } else if (user.userType === 'client') {
            return next({
                name: "ClientDashboard"
            })
        }else if (user.userType === 'clientadmin') {
            return next({
                name: "ClientAdminDashboard"
            })
        } else if (user.userType === 'shopkeeper') {
            return next({
                name: "ShopKeeperDashboard"
            })
        } else {
            return next()
        }
    }

    return next()
}
