const StationManagerDashboard = ()=>import(/* webpackChunkName: "js/clientAdmin" */'../pages/stationManager/Dashboard');
const StationManagerPumpAttendants = ()=>import(/* webpackChunkName: "js/clientAdmin" */'../pages/stationManager/PumpAttendants');
const StationManagerPumpAttendant = ()=>import(/* webpackChunkName: "js/clientAdmin" */'../pages/stationManager/SingleAttendant');
const StationManagerFuelOrderSummary = ()=>import(/* webpackChunkName: "js/clientAdmin" */'../pages/stationManager/FuelOrderSummary');
const StationManagerStationReport = ()=>import(/* webpackChunkName: "js/clientAdmin" */'../pages/stationManager/Stationreport');
const StationManagerPumpAttendantSummaries = ()=>import(/* webpackChunkName: "js/clientAdmin" */'../pages/stationManager/PumpAttendantSummaries');

export default [
    {
        name:"StationManagerDashboard",
        path:"/station-manager",
        component:StationManagerDashboard
    },
    {
        name:"StationManagerPumpAttendants",
        path:"/station-manager/pump-attendants",
        component:StationManagerPumpAttendants
    },
    {
        name:"StationManagerFuelOrderSummary",
        path:"/station-manager/fuelOrderSummary",
        component:StationManagerFuelOrderSummary
    },
    {
        name:"StationManagerStationReport",
        path:"/station-manager/stationSummary",
        component:StationManagerStationReport
    },
    {
        name:"StationManagerPumpAttendant",
        path:"/station-manager/pump-attendant/:id",
        component:StationManagerPumpAttendant,
        props:true
    },
    {
        name:"StationManagerPumpAttendantSummaries",
        path:"/station-manager/pump-attendant-summary",
        component:StationManagerPumpAttendantSummaries
    }
]
