const Login = () => import(/* webpackChunkName: "js/home" */'../pages/home/Login');
const Index = () => import(/* webpackChunkName: "js/home" */'../pages/home/Index');
const ForgotPassword = () => import(/* webpackChunkName: "js/home" */'../pages/home/ForgotPassword');
const VerifyPhone = () => import(/* webpackChunkName: "js/home" */'../pages/home/VerifyPhone');
const ChangePassword = () => import(/* webpackChunkName: "js/home" */'../pages/home/ChangePassword');
export default [
    {
        name: "home",
        component: Index,
        path: "/"
    },
    {
        name: "login",
        component: Login,
        path: "/login"
    },
    {
        name: "verify-phone",
        component: VerifyPhone,
        path: "/verify-phone"
    },
    {
        name: "forgot-password",
        component: ForgotPassword,
        path: "/forgot-password"
    },
    {
        name: "change-password",
        component: ChangePassword,
        path: "/change-password"
    }
]
