<template>
    <v-card elevation="4"
        dark
        class="pa-0"
        color="primary darken-2"
        :height="breadcrumbs?'150px':'80px'"
        outlined>
        <v-card-title class="justify-center align-center">Welcome, {{user.firstname}}</v-card-title>
        <v-card-subtitle class="justify-center text-center mb-1">
            This is CFL Lamu, your smart Fuel partner
        </v-card-subtitle>
        <v-card-text class="pa-0 text-center white--text" v-show="breadcrumbs">
            <v-breadcrumbs dark :items="items"></v-breadcrumbs>
        </v-card-text>
    </v-card>
</template>

<script>
    import {mapGetters} from 'vuex'
    export default {
        name: "HeaderItem",
        props:{
            breadcrumbs:{
                type:Boolean,
                default:true
            }
        },
        data(){
            return {
                user:{}
            }
        },
        methods:{
            ...mapGetters([
                'getUser'
            ]),
        },
        created() {
            this.user = this.getUser()
        },
        computed:{
            items(){
                let rout = this.$router.currentRoute.matched;
                // console.log(rout);
                let routes=[];
                for (let i = 0; i <rout.length ; i++) {
                    routes.push({
                        text: rout[i].path.replace(/^\/+/g, ''),
                        disabled: false,
                        href: rout[i].path,
                    })
                }
                // console.log(routes);
                return routes
            }
        }
    }
</script>

<style scoped>

</style>
