export default function auth ({ next, store }){
    if (!store.getters.getUser) {
        store.dispatch('logout_user');
        return next('/login')
    }
    let user = store.getters.getUser;
    if(user.userType!=='client'){
        // logout
        store.dispatch('logout_user');
        return next('/login')
    }
    return next()
}
