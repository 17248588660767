import Vue from 'vue'
import Echo from 'laravel-echo'

window.Pusher = require('pusher-js')
// window.io = require('socket.io-client');

// window.Echo = new Echo({
//     broadcaster: 'socket.io',
//     host: window.location.hostname + ':6001'
// });
export var echo_instance = new Echo({
    // broadcaster: 'socket.io',
    // host: 'https://api.cfl-lamu.com' + ':6001',
    // transport:['ws']
    // broadcaster: 'pusher',
    // // key: 'todoapp',
    // key: 'cflv3app',
    // wsHost: 'api.cfl-lamu.com',
    // authEndpoint : 'http://api.cfl-lamu.com/api/broadcasting/auth',
    // // wsHost: 'api.moffhubsolutions.com',
    // // authEndpoint : 'http://api.moffhubsolutions.com/api/broadcasting/auth',
    // wsPort: 6001,
    // // wssPort: 6001,
    // encrypted: true,
    // disableStats: true,
    // forceTLS:false,
    // auth: {
    //     headers: {
    //         Authorization: "Bearer " + localStorage.getItem('token')
    //     },
    // },
    broadcaster: "pusher",
    key: 'cflvkey',
    // cluster:'eu',
    encrypted: false,
    authEndpoint : 'http://api.cfl-lamu.com/api/broadcasting/auth',
    wsHost: 'api.cfl-lamu.com',
    wsPort: '6001',
    wssPort: '6001',
    disableStats: true,
    // enabledTransports: ['ws', 'wss'],
    // forceTLS:false,
    // host: 'api.cfl-lamu.com' + ':6001',
    auth: {
      headers: {
        /** I'm using access tokens to access  **/
        Authorization: "Bearer " + localStorage.getItem('token')
      }
    }
})

Vue.prototype.$echo = echo_instance