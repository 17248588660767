<template>
    <v-container>
        <v-navigation-drawer app
                             v-model="drawer"
                              clipped
                             disable-resize-watcher
                             width="250"
                             class="grey lighten-2">
            <v-list-item two-line class="blue lighten-2">
                <v-avatar color="white" size="50">
                    <v-img class="mx-2" src="/img/logo.png" max-height="40" max-width="40" contain/>
                </v-avatar>
                <v-list-item-content class="ml-1">
                    <v-list-item-title>{{ user.firstname + " " + user.lastname }}</v-list-item-title>
                    <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                    <v-list-item-subtitle>CFL Lamu</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>

            <v-list dense nav>
                <v-list-item v-for="item in items"
                             :key="item.title"
                             :to="item.to"
                             link
                             exact>
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider class="my-10"/>
                <v-list-item link class="red white--text" @click="logout">
                    <v-list-item-icon>
                        <v-icon color="white">mdi-logout</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="white--text">Logout</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <!--            </v-img>-->
        </v-navigation-drawer>
        <v-app-bar color="grey lighten-3" light fixed app clipped-left flat>
            <v-app-bar-nav-icon class="" @click="drawer = !drawer"/>
            <v-spacer class="hidden-sm-and-up"></v-spacer>
            <v-spacer/>
            <v-img class="mx-2" src="/img/logo.png" max-height="40" max-width="40" contain/>
            <v-toolbar-title class="headline text-center  ml-2 align-center">
                Central Fuels & Lubricants
            </v-toolbar-title>
            <v-spacer/>
        </v-app-bar>
    </v-container>
</template>

<script>
export default {
    name: 'ClientAdminNavigation',
    data() {
        return {
            appTitle: 'CFL LAMU',
            drawer: true,
            items: [
                {title: 'Dashboard', icon: 'mdi-view-dashboard', to: "/client-admin"},
                {title: 'Fuel Orders', icon: 'mdi-oil', to: "/client-admin/fuel-order"},
                {title: 'Fuel Log Reports', icon: 'mdi-history', to: "/client-admin/reports/fuel-log-summary"},
                {title: 'Fuel Order Reports', icon: 'mdi-currency-usd', to: "/client-admin/reports/fuel-order-summary"},
                {title: 'Driver Reports', icon: 'mdi-bus', to: "/client-admin/reports/driver-summary"},
                // {title: 'My Account', icon: 'mdi-login', to: "/client-admin/account"},
            ],
        };
    },
    computed: {
        isAuthenticated() {
            // return false
            return this.$store.getters.auth;
        }
    },
    created() {
        this.user = this.$store.state.user
    },
    methods: {
        logout() {
            swal.fire({
                title: 'Log out?',
                text: "You will be logged out and Redirected",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Log me out!'
            }).then((result) => {
                if (result.value) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('CFLLamu');
                    this.$store.dispatch('logout_user');
                    toast.fire(
                        'Logged out!',
                        'Redirecting ....',
                        'success'
                    );
                    this.$router.push('/login');
                }
            })
        }
    }
};
</script>

<style scoped>
a {
    color: white;
    text-decoration: none;
}
</style>
