// import Dashboard from "../pages/admin/Dashboard";
const Dashboard = () => import(/* webpackChunkName: "js/management" */'../pages/admin/Dashboard');
const ManagementShopKeepers = () => import(/* webpackChunkName: "js/shopkeepers" */'@/pages/admin/ManagementShopKeepers.vue');
const SingleAttendantReport = () => import(/* webpackChunkName: "js/management" */'../pages/admin/reports/SingleAttendantReport');
const ManagementStaff = () => import(/* webpackChunkName: "js/management" */'../pages/admin/ManageStaff');
const ManagementPayments = () => import(/* webpackChunkName: "js/management" */'../pages/admin/ManagementPayments');
const Messaging = () => import(/* webpackChunkName: "js/management" */'../pages/admin/Messaging');
const FullTankDefinitions = () => import(/* webpackChunkName: "js/management" */'../pages/admin/FullTankDefinitions');
const ManagementClients = () => import(/* webpackChunkName: "js/management" */'../pages/admin/ManagementClients');
const ManagementClientAccounts = () => import(/* webpackChunkName: "js/management" */'../pages/admin/ManagementAccounts');
const ManagementStations = () => import(/* webpackChunkName: "js/management" */'../pages/admin/ManagementStations');
const ManagementDriverEmployers = () => import(/* webpackChunkName: "js/management" */'../pages/admin/ManagementDriverEmployers');
const ManagementShowClient = () => import(/* webpackChunkName: "js/management" */'../pages/admin/ManagementShowClient');
const ManagementShowAccount = () => import(/* webpackChunkName: "js/management" */'../pages/admin/ManagementShowAccount');
const ManagementClientAdmins = () => import(/* webpackChunkName: "js/management" */'../pages/admin/ManagementClientAdmins');
const ManagementSingleClientAdmins = () => import(/* webpackChunkName: "js/management" */'../pages/admin/ShowClientAdmin');
const ManagementPumpAttendants = () => import(/* webpackChunkName: "js/management" */'../pages/admin/ManagementPumpAttendants');
const ManagementStationManagers = () => import(/* webpackChunkName: "js/management" */'../pages/admin/ManagementStationManager');
const ManagementSupervisors = () => import(/* webpackChunkName: "js/management" */'../pages/admin/ManagementSupervisors');
const ManagementDrivers = () => import(/* webpackChunkName: "js/management" */'../pages/admin/ManagementDrivers');
const DailyReports = () => import(/* webpackChunkName: "js/management" */'../pages/admin/reports/DailyReports');
const SystemLogs = () => import(/* webpackChunkName: "js/management" */'../pages/admin/reports/SystemLogs');
const DriverReports = () => import(/* webpackChunkName: "js/management" */'../pages/admin/reports/DriverReports');
const ClientSummaryReports = () => import(/* webpackChunkName: "js/management" */'../pages/admin/reports/ClientSummaryReports');
const FuelLogReports = () => import(/* webpackChunkName: "js/management" */'../pages/admin/reports/FuelLogReports');
const FuelOrderReports = () => import(/* webpackChunkName: "js/management" */'../pages/admin/reports/FuelOrderReports');
const PumpAttendantReports = () => import(/* webpackChunkName: "js/management" */'../pages/admin/reports/PumpAttendantReports');
const Permissions = () => import(/* webpackChunkName: "js/management-system" */'../pages/admin/system/Permissions');
const RolePermissions = () => import(/* webpackChunkName: "js/management-system" */'../pages/admin/system/RolePermissions');
const Roles = () => import(/* webpackChunkName: "js/management-system" */'../pages/admin/system/Roles');
const UserRoles = () => import(/* webpackChunkName: "js/management-system" */'../pages/admin/system/UserRoles');
const ManagementPumpAttendantSummaries = () => import(/* webpackChunkName: "js/management-system" */'../pages/admin/summaries/PumpAttendantSummary');
const ManagementFuelStationSummary = () => import(/* webpackChunkName: "js/management-system" */'../pages/admin/summaries/FuelStationSummary');
const ManagementDriverSummary = () => import(/* webpackChunkName: "js/management-system" */'../pages/admin/summaries/DriverSummary');
const ManagementClientBalanceSummary = () => import(/* webpackChunkName: "js/management-system" */'../pages/admin/summaries/ClientBalanceSummary');
const ManagementClientAdminSummary = () => import(/* webpackChunkName: "js/management-system" */'../pages/admin/summaries/ClientAdminSummary');
const DataImport = () => import(/* webpackChunkName: "js/management-system" */'../pages/admin/dataimport/DataImport');
const ManagementDirectOrder = () => import(/* webpackChunkName: "js/management-system" */'../pages/admin/directorder/DirecrtOrder');
const ManagementDirectOrderLogs = () => import(/* webpackChunkName: "js/management-system" */'../pages/admin/directorder/DirectOrderLogs');

export default [
    {
        name: "AdminDashboard",
        path: "/admin",
        component: Dashboard
    },
    {
        name: "AdminDrivers",
        path: "/admin/drivers",
        component: ManagementDrivers
    },
    {
        name: "DataImport",
        path: "/admin/data-import",
        component: DataImport
    },
    {
        name: "ManagementPayments",
        path: "/admin/mpesa-payments",
        component: ManagementPayments
    },
    {
        name: "ManagementDirectOrder",
        path: "/admin/direct-orders",
        component: ManagementDirectOrder
    },
    {
        name: "ManagementDirectOrderLogs",
        path: "/admin/direct-order/logs",
        component: ManagementDirectOrderLogs
    },
    {
        name: "FullTankDefinitions",
        path: "/admin/full-tank-details",
        component: FullTankDefinitions
    },
    {
        name: "AdminClients",
        path: "/admin/clients",
        component: ManagementClients
    },
    {
        name: "ManagementStationManagers",
        path: "/admin/station-managers",
        component: ManagementStationManagers
    },
    {
        name: "ManagementSupervisors",
        path: "/admin/supervisors",
        component: ManagementSupervisors
    },
    {
        name: "AdminShowClient",
        path: "/admin/client/:id",
        component: ManagementShowClient,
        props: true
    },
    {
        name: "ManagementShowAccount",
        path: "/admin/client-account/:id",
        component: ManagementShowAccount,
        props: true
    },
    {
        name: "ManagementSingleClientAdmins",
        path: "/admin/client-admin/:id",
        component: ManagementSingleClientAdmins,
        props: true
    },
    {
        name: "AdminShowAttendantReport",
        path: "/admin/attendant/:id",
        component: SingleAttendantReport,
        props: true
    },
    {
        name: "Messaging",
        path: "/admin/messaging",
        component: Messaging,
        props: true
    },
    {
        name: "AdminClientAdmins",
        path: "/admin/client-admins",
        component: ManagementClientAdmins
    },
    {
        name: "ManagementClientAccounts",
        path: "/admin/client-accounts",
        component: ManagementClientAccounts
    },
    {
        name: "ManagementStations",
        path: "/admin/pump-stations",
        component: ManagementStations
    },
    {
        name: "AdminStaff",
        path: "/admin/staff",
        component: ManagementStaff
    },
    {
        name: "AdminPumpAttendants",
        path: "/admin/pump-attendants",
        component: ManagementPumpAttendants
    },
    {
        name: "AdminShopKeepers",
        path: "/admin/shop-keepers",
        component: ManagementShopKeepers
    },
    {
        name: "ClientSummaryReports",
        path: "/admin/reports/client-summary",
        component: ClientSummaryReports
    },
    {
        name: "DailyReports",
        path: "/admin/reports/daily-summary",
        component: DailyReports
    },
    {
        name: "SystemReports",
        path: "/admin/reports/system-log",
        component: SystemLogs
    },
    {
        name: "DriverReports",
        path: "/admin/reports/driver-summary",
        component: DriverReports
    },
    {
        name: "FuelLogReports",
        path: "/admin/reports/fuel-log-summary",
        component: FuelLogReports
    },
    {
        name: "FuelOrderReports",
        path: "/admin/reports/fuel-order-summary",
        component: FuelOrderReports
    },
    {
        name: "PumpAttendantReports",
        path: "/admin/reports/pump-attendant-summary",
        component: PumpAttendantReports
    },
    {
        name: "Permissions",
        path: "/admin/system/permissions",
        component: Permissions
    },
    {
        name: "RolePermissions",
        path: "/admin/system/role-permissions",
        component: RolePermissions
    },
    {
        name: "Roles",
        path: "/admin/system/roles",
        component: Roles
    },
    {
        name: "UserRoles",
        path: "/admin/system/user-roles",
        component: UserRoles
    },
    {
        name: "DriverEmployers",
        path: "/admin/driver-employers",
        component: ManagementDriverEmployers
    },
    {
        name: "ManagementPumpAttendantSummaries",
        path: "/admin/summaries/pump-attendants",
        component: ManagementPumpAttendantSummaries
    },
    {
        name: "ManagementFuelStationSummary",
        path: "/admin/summaries/fuel-stations",
        component: ManagementFuelStationSummary
    },
    {
        name: "ManagementDriverSummary",
        path: "/admin/summaries/drivers",
        component: ManagementDriverSummary
    },
    {
        name: "ManagementClientBalanceSummary",
        path: "/admin/summaries/client-balance",
        component: ManagementClientBalanceSummary
    },
    {
        name: "ManagementClientAdminSummary",
        path: "/admin/summaries/client-admin-summaries",
        component: ManagementClientAdminSummary
    },
    {
        name: "Products",
        path: "/admin/finance/products",
        component: () => import('@/pages/admin/finances/Products.vue')
    },
    {
        name: "Quotes",
        path: "/admin/finance/quotes",
        component: () => import('@/pages/admin/finances/Quotes.vue')
    },
    {
        name: "SingleQuote",
        path: "/admin/finance/quote/:id",
        component: () => import('@/pages/admin/finances/SingleQuote.vue')
    },
    {
        name: "ProformaInvoices",
        path: "/admin/finance/proforma-invoices",
        component: () => import('@/pages/admin/finances/ProformaInvoices.vue')
    },
    {
        name: "Invoices",
        path: "/admin/finance/invoices",
        component: () => import('@/pages/admin/finances/Invoices.vue')
    },
    {
        name: "CashSales",
        path: "/admin/finance/cash-sale",
        component: () => import('@/pages/admin/finances/CashSale.vue')
    },
    {
        name: "SingleCashSale",
        path: "/admin/finance/create-cash-sale/:id",
        component: () => import('@/pages/admin/finances/CreateCashSale.vue')
    },
    {
        name: "CreditNotes",
        path: "/admin/finance/credit-notes",
        component: () => import('@/pages/admin/finances/CreditNotes.vue')
    },
    {
        name: "SingleCreditNote",
        path: "/admin/finance/create-credit-note/:id",
        component: () => import('@/pages/admin/finances/CreateCreditNote.vue')
    },
    {
        name: "DebitNotes",
        path: "/admin/finance/debit-notes",
        component: () => import('@/pages/admin/finances/DebitNotes.vue')
    },
    {
        name: "SingleDebitNote",
        path: "/admin/finance/create-debit-note/:id",
        component: () => import('@/pages/admin/finances/CreateDebitNote.vue')
    },
    {
        name: "Shops",
        path: "/admin/finance/users/shops",
        component: () => import('@/pages/admin/financeUsers/Shops.vue')
    },
    {
        name: "ShopKeepers",
        path: "/admin/finance/users/shop-keepers",
        component: () => import('@/pages/admin/financeUsers/ShopKeepers.vue')
    }
]
