<template>
    <v-app app>
        <supervisor-navigation app class="mb-0"/>
        <v-main>
            <router-view/>
        </v-main>
        <v-footer app
                  color="primary darken-2"
            inset
            absolute
            padless>
            <v-container fluid>
                <v-row class="primary darken-2 text-center white--text ma-0"
                       justify="center"
                       cols="12">
                    <span class="caption">CFL Lamu Ltd &copy; {{ new Date().getFullYear() }} www.cfl-lamu.com</span>
                    <v-spacer/>
                    <span class="caption mr-1">Built by MoffHub Solutions  </span>
                </v-row>
            </v-container>
        </v-footer>
    </v-app>
</template>

<script>
    import ClientAdminNavigation from "../components/admin_components/ClientAdminNavigation";
    import SupervisorNavigation from "../components/admin_components/SupervisorNavigation";

    export default {
        name: "supervisorPageLayout",
        components: {SupervisorNavigation, ClientAdminNavigation}
    }
</script>

<style scoped>

</style>
