const SupervisorDashboard = ()=>import(/* webpackChunkName: "js/clientAdmin" */'../pages/supervisor/ClAdDashboard');
const SupervisorClientPage = ()=>import(/* webpackChunkName: "js/supervisor" */'../pages/supervisor/ClAdClientPage');
const SupervisorAccountPage = ()=>import(/* webpackChunkName: "js/supervisor" */'../pages/supervisor/ClAdminAccountPage');
const SupervisorLogs = ()=>import(/* webpackChunkName: "js/supervisor" */'../pages/supervisor/ClAdLogs');
const SupervisorFuelOrder = ()=>import(/* webpackChunkName: "js/supervisor" */'../pages/supervisor/ClAdFuelOrder');
const SupervisorFuelReports = ()=>import(/* webpackChunkName: "js/supervisor" */'../pages/supervisor/reports/ClAdFuelReports');
const SupervisorDriverReports = ()=>import(/* webpackChunkName: "js/supervisor" */'../pages/supervisor/reports/ClAdDriverReports');
export default [
    {
        name:"SupervisorDashboard",
        path:"/supervisor",
        component:SupervisorDashboard
    },
    {
        name:"SupervisorClientPage",
        path:"/supervisor/client/:id",
        component:SupervisorClientPage,
        props:true
    },
    {
        name:"SupervisorAccountPage",
        path:"/supervisor/account/:id",
        component:SupervisorAccountPage,
        props:true
    },
    {
        name:"SupervisorFuelOrder",
        path:"/supervisor/fuel-order",
        component:SupervisorFuelOrder,
    },
    {
        name:"SupervisorLogs",
        path:"/supervisor/reports/fuel-log-summary",
        component:SupervisorLogs,
    },
    {
        name:"SupervisorFuelReports",
        path:"/supervisor/reports/fuel-order-summary",
        component:SupervisorFuelReports,
    },
    {
        name:"SupervisorDriverReports",
        path:"/supervisor/reports/driver-summary",
        component:SupervisorDriverReports,
    }
]
