<template>
  <v-app>
    <navigation/>
    <v-main>
      <router-view/>
      <v-btn v-scroll="onScroll" style="z-index: 999999" class="flot" v-show="fab" fab fixed dark bottom small right color="primary" @click="toTop">
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-main>
    <v-footer app
              color="grey lighten-3"
              light
    absolute>
      <v-container fluid>
        <!--        <v-card flat class="black&#45;&#45;text text-center">-->

        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="4" sm="12" class="justify-end text-start">
              <v-row class="text-h5 ">
                CFL Limited
              </v-row>
              <div class="body-2">
              </div>
            </v-col>

            <v-col cols="12" md="4" sm="12" class="justify-end text-start">
              <v-row class="text-h5">
                Quick Links
              </v-row>
              <v-row dense>
                <v-col cols="12" md="12" sm="12">
                  <v-row dense>
                    <v-col cols="6" xs="6">
                      <router-link to="/home">Home</router-link>
                    </v-col>
                    <v-col cols="6" xs="6">
                      <a href="https://play.google.com/store/apps/details?id=com.moffhubsolutions.cfllamu">Download App</a>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-row dense>
                    <v-col cols="6" xs="6">
                      <router-link to="">T&C's</router-link>
                    </v-col>
                    <v-col cols="6" xs="6">
                      <router-link to="/">Fuel Guide</router-link>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-row dense>
                    <v-col cols="6" xs="6">
                      <router-link to="">Our Locations</router-link>
                    </v-col>
                    <v-col cols="6" xs="6">
                      <router-link to="/">Our Services</router-link>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="4" sm="12" class="justify-end text-start">
              <v-row class="text-h5">
                Useful Links
              </v-row>
              <v-row dense>
                <v-col cols="12" md="12" sm="12">
                  <v-row dense>
                    <v-col cols="6" xs="6">
                      <router-link to="">Mechanic Services</router-link>
                    </v-col>
                    <v-col cols="6" xs="6">
                      <router-link to="/">Towing Services</router-link>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-row dense>
                    <v-col cols="6" xs="6">
                      <router-link to="">Our Socials</router-link>
                    </v-col>
                    <v-col cols="6" xs="6">
                      <router-link to="/">Complaints</router-link>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="">
          <v-row dense class="" justify="center">
            <v-col cols="12" md="4" class="justify-center">
              <span class="">CFL Lamu Ltd &copy; {{ new Date().getFullYear() }}</span>
            </v-col>
            <v-col cols="12" md="4" class="justify-center">
              <span class=""> www.cfl-lamu.com </span>
            </v-col>
            <v-col cols="12" md="4" class="justify-center">
              <span class="">Made by <a href="https://moffhubsolutions.com" class="blue--text" target="_blank">Moffhub Solutions</a> </span>
            </v-col>
          </v-row>
        </v-card-text>
        <!--        </v-card>-->
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import Navigation from "../components/home_components/Navigation";

export default {
  name: "homePageLayout",
  components: {
    Navigation
  },
  data() {
    return {
      fab: true,
      links: [
        'Home',
        'About Us',
        'Contact Us',
      ],
    }
  },
  methods: {
    onScroll(e) {
      if (typeof window === 'undefined') return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 40
    },
    toTop() {
      this.$vuetify.goTo(0)
    }
  }
}
</script>

<style scoped>

</style>
