import Vue from "vue"
import Vuex from "vuex"
import VuexPersist from 'vuex-persist';


Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
    key: 'CFLLamu' ,// The key to store the state on in the storage provider.
    storage: window.localStorage, // or window.sessionStorage or localForage
    // Function that passes the state and returns the state with only the objects you want to store.
    // reducer: state => state,
    // Function that passes a mutation and lets you decide if it should update the state in localStorage.
    // filter: mutation => (true)
});

export default new Vuex.Store({
    plugins: [vuexLocalStorage.plugin],
    state: {
        user: {},
        isLoggedIn:false,
    },
    mutations: {
        ADD_USER: (state, user) => {
            state.user = user;
            state.isLoggedIn = true;
        },
        LOGOUT:(state)=>{
            state.user = null;
            state.isLoggedIn = false;
        }
    },
    actions: {
        addUser: (context, user) => {
            context.commit('ADD_USER', user)
        },
        logout_user:(context)=>{
            context.commit('LOGOUT');
        }
    },
    getters: {
        getUser: state => state.user,
        auth:state =>state.isLoggedIn
    }
})
