<template>
    <v-container>
        <v-navigation-drawer clipped app v-model="drawer" width="260"
                             disable-resize-watcher class="grey lighten-2">
            <template v-slot:prepend>
                <v-list-item two-line class="blue lighten-2">
                    <v-avatar color="" size="30">
                        <v-img class="" src="/img/logo.png" max-height="40" max-width="40" contain/>
                    </v-avatar>
                    <v-list-item-content class="ml-1">
                        <v-list-item-title>{{ user.firstname + " " + user.lastname }}</v-list-item-title>
                        <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                        <v-list-item-subtitle>CFL Lamu</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <v-divider/>
            <v-list nav
                    dense>
                <v-list-item link :to="{name:'AdminDashboard'}" exact>
                    <v-list-item-icon>
                        <v-icon>mdi-home</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Home</v-list-item-title>
                </v-list-item>
                <v-list-group
                        prepend-icon="mdi-currency-usd"
                        no-action>
                    <template v-slot:activator>
                        <v-list-item-title>Finances</v-list-item-title>
                    </template>

                    <v-list-item
                            v-for="(admin, i) in finances"
                            :key="i"
                            :to="{name:admin.to}"
                            link>
                        <v-list-item-title v-text="admin.title"></v-list-item-title>
                    </v-list-item>
                </v-list-group>
                <v-list-group
                        prepend-icon="mdi-currency-usd"
                        no-action>
                    <template v-slot:activator>
                        <v-list-item-title>Finance Users</v-list-item-title>
                    </template>

                    <v-list-item
                            v-for="(admin, i) in financeUsers"
                            :key="i"
                            :to="{name:admin.to}"
                            link>
                        <v-list-item-title v-text="admin.title"></v-list-item-title>
                    </v-list-item>
                </v-list-group>
                <v-list-group
                        prepend-icon="mdi-currency-usd"
                        no-action>
                    <template v-slot:activator>
                        <v-list-item-title>Finance Reports</v-list-item-title>
                    </template>

                    <v-list-item
                            v-for="(admin, i) in financeReports"
                            :key="i"
                            :to="{name:admin.to}"
                            link>
                        <v-list-item-title v-text="admin.title"></v-list-item-title>
                    </v-list-item>
                </v-list-group>
                <v-list-group
                        prepend-icon="mdi-gas-station"
                        no-action>
                    <template v-slot:activator>
                        <v-list-item-title>Direct Fuel Order</v-list-item-title>
                    </template>

                    <v-list-item
                            v-for="(admin, i) in directFuel"
                            :key="i"
                            :to="{name:admin.to}"
                            link>
                        <v-list-item-title v-text="admin.title"></v-list-item-title>
                    </v-list-item>
                </v-list-group>
                <v-list-group
                        prepend-icon="mdi-wrench"
                        no-action>
                    <template v-slot:activator>
                        <v-list-item-title>Utilities</v-list-item-title>
                    </template>

                    <v-list-item
                            v-for="(admin, i) in utilities"
                            :key="i"
                            :to="{name:admin.to}"
                            link>
                        <v-list-item-title v-text="admin.title"></v-list-item-title>
                    </v-list-item>
                </v-list-group>
                <v-list-group
                        prepend-icon="mdi-account-multiple"
                        no-action>
                    <template v-slot:activator>
                        <v-list-item-title>Users</v-list-item-title>
                    </template>

                    <v-list-item
                            v-for="(admin, i) in users"
                            :key="i"
                            :to="{name:admin.to}"
                            link>
                        <v-list-item-title v-text="admin.title"></v-list-item-title>
                    </v-list-item>
                </v-list-group>
                <v-list-group
                        prepend-icon="mdi-graph"
                        no-action>
                    <template v-slot:activator>
                        <v-list-item-title>Reports</v-list-item-title>
                    </template>

                    <v-list-item
                            v-for="(admin, i) in reports"
                            :key="i"
                            :to="{name:admin.to}"
                            link>
                        <v-list-item-title v-text="admin.title"></v-list-item-title>
                    </v-list-item>
                </v-list-group>
                <v-list-group
                        prepend-icon="mdi-book"
                        no-action>
                    <template v-slot:activator>
                        <v-list-item-title>Summaries</v-list-item-title>
                    </template>

                    <v-list-item
                            v-for="(admin, i) in summaries"
                            :key="i"
                            :to="{name:admin.to}"
                            link>
                        <v-list-item-title v-text="admin.title"></v-list-item-title>
                    </v-list-item>
                </v-list-group>
                <v-list-item
                        dense
                        link
                        class="red white--text"
                        @click="logout">
                    <v-list-item-icon>
                        <v-icon class="white--text">mdi-lock-open</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="white--text">Logout</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <v-app-bar flat color="grey lighten-3" light fixed app clipped-left>
            <v-app-bar-nav-icon class="" @click="drawer = !drawer"/>
            <v-spacer class="hidden-sm-and-up"></v-spacer>

            <v-spacer/>
            <v-img class="mx-2" src="/img/logo.png" max-height="40" max-width="40" contain/>
            <v-toolbar-title class="heading text-center ml-2 align-center">Central Fuels &
                Lubricants
            </v-toolbar-title>
            <v-spacer/>
            <div class="hidden-sm-and-down">
                <v-menu bottom :offset-y="offset">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                                text
                                v-bind="attrs"
                                v-on="on">
                            Account
                            <v-icon right>mdi-account</v-icon>
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item
                                color="red"
                                class="red--text"
                                @click="logout">
                            <v-list-item-title>Logout</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </v-app-bar>

    </v-container>
</template>

<script>

export default {
    name: 'AppNavigation',
    data() {
        return {
            appTitle: 'CFL LAMU',
            drawer: true,
            offset: true,
            user: [],
            items: [
                {title: 'Profile', to: ""},
                {title: 'Settings', to: ""},
            ],
            utilities: [
                {title: 'Messaging', to: "Messaging"},
                {title: 'Petrol Stations', to: "ManagementStations"},
                {title: 'Full Tank Details', to: "FullTankDefinitions"},
                {title: 'Mpesa Payments', to: "ManagementPayments"},
            ],
            directFuel: [
                {title: 'Direct Order', to: "ManagementDirectOrder"},
                {title: 'Direct Order Logs', to: "ManagementDirectOrderLogs"},
            ],
            users: [
                {title: 'Clients', to: "AdminClients"},
                {title: 'Client Accounts', to: "ManagementClientAccounts"},
                {title: 'Client Admins', to: "AdminClientAdmins"},
                {title: 'Drivers', to: "AdminDrivers"},
                {title: 'Supervisors', to: "ManagementSupervisors"},
                {title: 'Staff', to: "AdminStaff"},
                {title: 'Other Driver Employers', to: "DriverEmployers"},
                {title: 'Pump Attendants', to: "AdminPumpAttendants"},
                {title: 'Shop Keepers', to: "AdminShopKeepers"},
                {title: 'Station Managers', to: "ManagementStationManagers"},
            ],
            clientUsers: [
                {title: 'Clients', to: "AdminClients"},
                {title: 'Client Accounts', to: "ManagementClientAccounts"},
                {title: 'Client Admins', to: "AdminClientAdmins"},
                {title: 'Drivers', to: "AdminDrivers"},
                {title: 'Supervisors', to: "AdminClientAdmins"},
            ],
            reports: [
                {title: 'Fuel Log Reports', to: "FuelLogReports"},
                {title: 'Fuel Order Reports', to: "FuelOrderReports"},
                {title: 'Pump Attendant Reports', to: "PumpAttendantReports"},
                {title: 'Driver Reports', to: "DriverReports"},
                {title: 'System Logs', to: "SystemReports"},
            ],
            summaries: [
                {title: 'Attendant Summaries', to: "ManagementPumpAttendantSummaries"},
                {title: 'C. Admin Summaries', to: "ManagementClientAdminSummary"},
                {title: 'Station Summaries', to: "ManagementFuelStationSummary"},
                {title: 'Driver Summaries', to: "ManagementDriverSummary"},
                {title: 'Client Account Summaries', to: "ManagementClientBalanceSummary"},
            ],
            system: [
                {title: 'Roles', to: "Roles"},
                {title: 'Permissions', to: "Permissions"},
                {title: 'Role-Permissions', to: "RolePermissions"},
                {title: 'User-Roles', to: "UserRoles"},
            ],
            finances: [
                {title: 'Products', to: "Products"},
                {title: 'Quotes', to: "Quotes"},
                {title: 'Proforma Invoice', to: "ProformaInvoices"},
                {title: 'Invoices', to: "Invoices"},
                {title: 'Cash Sale', to: "CashSales"},
                {title: 'Credit Notes', to: "CreditNotes"},
                {title: 'Debit Notes', to: "DebitNotes"},
            ],
            financeUsers: [
                {title: 'Shops', to: "Shops"},
                {title: 'Shop Keepers', to: "ShopKeepers"},
            ],
            financeReports: [
                {title: 'Statements', to: "Shops"},
                {title: 'Aging Report', to: "ShopKeepers"},
            ],
            iteems: [
                {title: 'Dashboard', icon: 'mdi-view-dashboard', to: "/admin"},
                {title: 'Messaging', icon: 'mdi-message', to: "/admin/messaging"},
                {title: 'Staff', icon: 'mdi-account', to: "/admin/staff"},
                {title: 'Clients', icon: 'mdi-account-circle', to: "/admin/clients"},
                {title: 'Client Admins', icon: 'mdi-account', to: "/admin/client-admins"},
                {title: 'Driver Employers', icon: 'mdi-account', to: "/admin/driver-employers"},
                {title: 'Drivers', icon: 'mdi-account', to: "/admin/drivers"},
                {title: 'Pump Attendant', icon: 'mdi-account', to: "/admin/pump-attendants"},
                {title: 'Fuel Log Reports', icon: 'mdi-gas-station', to: "/admin/reports/fuel-log-summary"},
                {title: 'Fuel Order Reports', icon: 'mdi-oil', to: "/admin/reports/fuel-order-summary"},
                {
                    title: 'Pump Attendant Reports',
                    icon: 'mdi-gas-station',
                    to: "/admin/reports/pump-attendant-summary"
                },
                {title: 'Driver Reports', icon: 'mdi-car', to: "/admin/reports/driver-summary"},
                {title: 'Roles', icon: 'mdi-view-dashboard', to: "/admin/system/roles"},
                {title: 'Permissions', icon: 'mdi-lock', to: "/admin/system/permissions"},
                {title: 'Role-Permissions', icon: 'mdi-lock-open', to: "/admin/system/role-permissions"},
                {title: 'User-Roles', icon: 'mdi-view-dashboard', to: "/admin/system/user-roles"},
                // {title: 'My Account', icon: 'mdi-login', to: "/login"},
            ],
        };
    },
    created() {
        this.user = this.$store.state.user
    },
    computed: {
        isAuthenticated() {
            // return false
            return this.$store.getters.auth;
        }
    },
    methods: {
        goTo(to) {
            this.$router.push({
                name: to
            })
        },
        logout() {
            swal.fire({
                title: 'Log out?',
                text: "You will be logged out and Redirected",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Log me out!'
            }).then((result) => {
                if (result.value) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('CFLLamu');
                    this.$store.dispatch('logout_user');
                    toast.fire(
                        'Logged out!',
                        'Redirecting ....',
                        'success'
                    );
                    this.$router.push('/login');
                }
            })
        }
    }
};
</script>

<style scoped>
a {
    color: white;
    text-decoration: none;
}
</style>
