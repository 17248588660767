const ClientAdminDashboard = ()=>import(/* webpackChunkName: "js/clientAdmin" */'../pages/clientAdmin/ClAdDashboard');
const ClAdClientPage = ()=>import(/* webpackChunkName: "js/clientAdmin" */'../pages/clientAdmin/ClAdClientPage');
const ClAdAccountPage = ()=>import(/* webpackChunkName: "js/clientAdmin" */'../pages/clientAdmin/ClAdminAccountPage');
const ClAdLogs = ()=>import(/* webpackChunkName: "js/clientAdmin" */'../pages/clientAdmin/ClAdLogs');
const ClAdFuelOrder = ()=>import(/* webpackChunkName: "js/clientAdmin" */'../pages/clientAdmin/ClAdFuelOrder');
const ClAdFuelReports = ()=>import(/* webpackChunkName: "js/clientAdmin" */'../pages/clientAdmin/reports/ClAdFuelReports');
const ClAdDriverReports = ()=>import(/* webpackChunkName: "js/clientAdmin" */'../pages/clientAdmin/reports/ClAdDriverReports');
export default [
    {
        name:"ClientAdminDashboard",
        path:"/client-admin",
        component:ClientAdminDashboard
    },
    {
        name:"ClAdClientPage",
        path:"/client-admin/client/:id",
        component:ClAdClientPage,
        props:true
    },
    {
        name:"ClAdAccountPage",
        path:"/client-admin/account/:id",
        component:ClAdAccountPage,
        props:true
    },
    {
        name:"ClAdFuelOrder",
        path:"/client-admin/fuel-order",
        component:ClAdFuelOrder,
    },
    {
        name:"ClAdLogs",
        path:"/client-admin/reports/fuel-log-summary",
        component:ClAdLogs,
    },
    {
        name:"ClAdFuelReports",
        path:"/client-admin/reports/fuel-order-summary",
        component:ClAdFuelReports,
    },
    {
        name:"ClAdDriverReports",
        path:"/client-admin/reports/driver-summary",
        component:ClAdDriverReports,
    }
]
